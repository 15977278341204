import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import TravelExplorer from "./TravelExplorer";
import { PrivateRouter } from "./PrivateRouter";
import { PublicRouter } from "./PublicRouter";
import { TravelCoordinator } from "../screen/TravelCoordinator/TravelCoordinator";
import { Login } from "../components/Login/Login";
import { PoliciesAndConditions } from "../components/MainInformation/PoliciesAndConditions/PoliciesAndConditions";
import { DescriptionCard } from "../screen/DescriptionCard/DescriptionCard";
import { Reservation } from "../screen/Reservation/Reservation";
import { WebView } from "../screen/Web/WebView";
import { NavBarLayout } from "../components/NavBarLayout/NavBarLayout";

export const AppRouter = ({ dataTurims, dataDestinations }) => {

  // console.log("dataTurims", dataTurims);
  // console.log("dataDestinations", dataDestinations);
  
  
  const { logged } = useSelector((state) => state.user);

  const createRoute = (path, element, isPrivate = false , NavAndFoo = true) => {

    const Wrapper = isPrivate ? PrivateRouter : PublicRouter;

    return (
      <Route
        path={path}
        element={
          <Wrapper>
            <NavBarLayout NavAndFoo ={ NavAndFoo }>{element}</NavBarLayout>
          </Wrapper>
        }
      />
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        {logged ? (
          <>
            {createRoute("/*", <TravelExplorer />, true)}
            {createRoute("/turismos", (
              <TravelCoordinator
                data={dataTurims}
                title={"Los mejores paquetes turísticos"}
                secondTitle={"Nuestros turísmos"}
                path="/turismos"
                titleButon=" Buscar Paquete turísticos"
              />
            ), true)}
            {createRoute("/destinos", (
              <TravelCoordinator
                data={dataDestinations}
                title={"Los mejores destinos"}
                secondTitle={"Nuestros destinos"}
                path="/destinos"
                titleButon=" Buscar destino"
              />
            ), true)}
            {createRoute("/reservar", (
              <DescriptionCard
                dataDestination={dataDestinations}
                dataTurism={dataTurims}
              />
            ), true)}
            {createRoute("/reservar-programa", <Reservation />, true)}
            {createRoute("/politicas", <PoliciesAndConditions />, true)}
            {createRoute("/paymentNotification", <WebView />, true , false)}
          </>
        ) : (
          <>
            {createRoute("/", <TravelExplorer />)}
            {createRoute("/turismos", (
              <TravelCoordinator
                data={dataTurims}
                title={"Los mejores paquetes turísticos"}
                secondTitle={"Nuestros turísmos"}
                path="/turismos"
                titleButon=" Buscar Paquete turísticos"
              />
            ))}
            {createRoute("/destinos", (
              <TravelCoordinator
                data={dataDestinations}
                title={"Los mejores destinos"}
                secondTitle={"Nuestros destinos"}
                path="/destinos"
                titleButon=" Buscar destino"
              />
            ))}
            {createRoute("/paymentNotification", <WebView />, false , false)}
            {createRoute("/Login", <Login /> , false , false)}
            {createRoute("/politicas", <PoliciesAndConditions />)}
            {createRoute("/reservar", (
              <DescriptionCard
                dataDestination={dataDestinations}
                dataTurism={dataTurims}
              />
            ))}
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};
