import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
const server = process.env.REACT_APP_CLIENT_URL;

const client = new ApolloClient({
  uri: `${server}`,
  cache: new InMemoryCache(),
});

export const getAllDestinations = async () => {
    return new Promise((resolve, reject) => {
        client
        .query({
            query: gql`
            query {
                getAllProgramming{
                    result{ 
                      _id
                      id
                      images
                      dcto
                      price
                      pricedcto
                      capacity
                      status
                      available
                      descripcion
                      places{
                        name
                      }
                      statusfuec
                      fuec
                      tour{
                        origin{
                          name
                          departament
                        }
                        destination{
                          name
                          departament
                        }
                      }
                      start
                      end
                    }
                  }
            }
            `,
        })
        .then((result) => {
            if (result.data.getAllProgramming.result !== null) {
            resolve(result.data.getAllProgramming.result);
            } else {
            reject(result.data.getAllProgramming.message);
            }
        });
    });
    };


