import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    adults: 0,
    children: 0,
    totalPassengers: 0,
};

const passengersSlice = createSlice({
    name: "totalpassengers",
    initialState,

    reducers: {
        setAdultPassengers: (state, action) => {
            state.adults = action.payload;
            state.totalPassengers = state.adults + state.children;
        },
        setChildPassengers: (state, action) => {
            state.children = action.payload;
            state.totalPassengers = state.adults + state.children;
        },
        resetPassengers: (state) => {
            state.adults = 0;
            state.children = 0;
            state.totalPassengers = 0;
          },
    },
});

export const { setAdultPassengers, setChildPassengers,  resetPassengers } = passengersSlice.actions;
export default passengersSlice.reducer;
