import { getAllDestinations } from "../services/destinations";
import { getAllTurims } from "../services/turims";

export const getFilterDataDates = async () => {
  try {
    const response = await getAllTurims();
    // console.log("response en hook:", response);

    // Normalizamos la fecha de hoy a las 00:00:00 para evitar problemas con horas
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayMillis = today.getTime();

    // Filtramos los paquetes según la lógica correcta y verificamos el estado
    const filteredResponse = response.filter((turim) => {
      const idaMillis = Number(turim.ida) || 0;
      const vueltaMillis = Number(turim.vuelta) || 0;
      const isPending = turim.status === "Pendiente"; // Verificamos si el estado es "Pendiente"

      if (!isPending) return false; // Si no es "Pendiente", descartamos el elemento

      return turim.paqueteDiario ? vueltaMillis >= todayMillis : idaMillis >= todayMillis;
    });

    return filteredResponse;
  } catch (error) {
    console.error("Error fetching turims:", error);
    throw error;
  }
};

export const getFilterDatesProgramings = async () => {
  try {
    const response = await getAllDestinations();
    // console.log("response en APP.js:", response);

    // Normalizamos la fecha de hoy a las 00:00:00 para evitar problemas con horas
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayMillis = today.getTime();

    // Filtramos usando la fecha de inicio (start) y verificamos el estado
    const filteredResponse = response.filter((destination) => {
      const startDate = Number(destination.start) || 0;
      const isPending = destination.status === "Pendiente"; // Verificamos si el estado es "Pendiente"

      return isPending && startDate >= todayMillis; // Se filtran solo los elementos con estado "Pendiente"
    });

    return filteredResponse;
  } catch (error) {
    console.error("Error fetching destinations:", error);
    throw error;
  }
};
