
export const searchDataFilter = (data, searchParams) => {
  // console.log("searchParams en el nuevo buscador ", searchParams, data);
  let filteredData = [...data];

  const startDateMillis = new Date(searchParams.startDate);
  const endDateMillis = new Date(searchParams.endDate);
  const today = new Date();

  const isoDateStart = startDateMillis.getTime();
  const isoDateEnd = endDateMillis.getTime();
  const isoToday = today.getTime();

  // console.log(
  //   "startDateMillis--------------",
  //   isoDateStart,
  //   "endDateMillis------------------",
  //   isoDateEnd
  // );

  if (searchParams.origin) {
    filteredData = filteredData.filter((item) =>
      item.origen
        ? item.origen.some((place) =>
            place.name.toLowerCase().includes(searchParams.origin.toLowerCase())
          )
        : item.tour.origin.name
            .toLowerCase()
            .includes(searchParams.origin.toLowerCase())
    );
  }

  if (searchParams.destination) {
    filteredData = filteredData.filter((item) =>
      item.destino
        ? item.destino.name
            .toLowerCase()
            .includes(searchParams.destination.toLowerCase())
        : item.tour.destination.name
            .toLowerCase()
            .includes(searchParams.destination.toLowerCase())
    );
  }
  if (searchParams.startDate || searchParams.endDate) {
    // Normalizamos la fecha de búsqueda a las 00:00:00 en la zona horaria local
    const searchTimestamp = new Date(searchParams.startDate);
    searchTimestamp.setHours(0, 0, 0, 0); // Forzamos la hora a 00:00:00
    const searchMillis = searchTimestamp.getTime();
  
    filteredData = filteredData.filter((item) => {
      // Convertimos item.start y item.end a números y normalizamos a 00:00:00
      const itemStart = new Date(Number(item.start || item.ida));
      itemStart.setHours(0, 0, 0, 0);
      const itemStartMillis = itemStart.getTime();
  
      const itemEnd = new Date(Number(item.end || item.vuelta));
      itemEnd.setHours(23, 59, 59, 999); // Para incluir todo el día
      const itemEndMillis = itemEnd.getTime();
  
      // console.log("itemStartMillis", itemStartMillis);
      // console.log("itemEndMillis", itemEndMillis);
      // console.log("searchMillis", searchMillis);
  
      return searchMillis >= itemStartMillis && searchMillis <= itemEndMillis;
    });
  }
  
  
  // if (searchParams.endDate) {
  //   filteredData = filteredData.filter((item) => {
  //     if (item.vuelta <= isoDateEnd) {
  //       return true;
  //     } else if (item.end) {
  //       return isoDateEnd <= item.end;
  //     }
  //     return false;
  //   });
  // }

  if (searchParams.availableSeats) {
    filteredData = filteredData.filter((item) => {
      if (item.disponibles) {
        return item.disponibles >= parseInt(searchParams.availableSeats);
      } else if (item.paqueteDiario && item.cuposPorDia) {
        return item.cuposPorDia.some(
          (cupo) => cupo.disponibles >= parseInt(searchParams.availableSeats)
        );
      }  else if(item.available){
        item.available >= parseInt(searchParams.availableSeats);

        return item.available >= parseInt(searchParams.availableSeats);

      }else {
        return false;
      }
    });
  }

  if (searchParams.title) {
    // console.log("searchParams.title en el filtro", searchParams.title);
    filteredData = filteredData.filter((item) => {
      return item.nombrepaq?.toLowerCase().includes(searchParams.title.toLowerCase()); 
    });
  }

  return filteredData;
};
