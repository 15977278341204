import "./style.css";
import { useEffect, useState } from "react";
import { Carrusel } from "./Carrusel";
import { getFilterDataDates, getFilterDatesProgramings } from "../../../hooks/dataFilterDates";

export const RecommendedFilters = () => {
  const [destinations, setDestinations] = useState([]);
  const [tours, setTours] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [destinationsData, toursData] = await Promise.all([
          getFilterDatesProgramings(),
          getFilterDataDates(),
        ]);

        setDestinations(destinationsData);
        setTours(toursData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="containerRecommended">
      <Carrusel title="Turismos recomendados" data={tours} />
      <Carrusel title="Destinos recomendados" data={destinations} />
    </div>
  );
};
