import { formatPrice } from "../../utils/formatPrice";
import { useSelector } from "react-redux";
import {
  formatDate,
  formatDateTime,
  formatDateTimeDuration,
  formateNewDateOfCalendar,
} from "../../utils/formatDate";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { PassengerQuantity } from "../PassengerQuantity/PassengerQuantity";
import SelectCalendarDates from "../selectCalendarDates/SelectCalendarDates";
import { useDispatch } from "react-redux";
import { getDateUser } from "../../redux/dateSlice";
import { LottieAnimation } from "../../components/LottieAnimation/LottieAnimation";
import useIncludes from "../../hooks/incluyesOrNoIncluyes";
import "./styles.css";
import { SecondItemDescriptionProgrammings } from "./SecondItemDescriptionProgrammings";

export const SecondItemDescriptionCard = ({
  typeProgramg,
  activeReservation = true,
  counter,
}) => {
  const dispatch = useDispatch();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateSelect, setDateSelect] = useState("");
  const [avaliableDaySelected, setAvaliableDaySelected] = useState(0);
  const [isLoading, setIsLoading] = useState(true);


  const itemRedux = useSelector((state) => state.itemProduct);
  const navigate = useNavigate();
  const userIsloged = useSelector((state) => state.user.logged);
  const userCounter = useSelector((state) => state.passengers);
  const [initialPrice, setInitialPrice] = useState(0);

  const [initialPriceF, setInitialPriceF] = useState(
    itemRedux.priceDcto ? itemRedux.priceDcto : itemRedux.price
  );

  const showCalendarState = () => setShowCalendar(false);
  const userDateSelect = (date) => setDateSelect(date);




  useEffect(() => {
    totalPriceItems();
  }, [userCounter]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    if (isLoading) {
      document.body.classList.add("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isLoading]);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setNumberPerPage(getNumberPerPage());
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [window.innerWidth]);

  useEffect(() => {
    if (dateSelect !== "") {
      const fecha = new Date(dateSelect);

      // Normalizar la fecha seleccionada al inicio del día (00:00:00) en UTC
      fecha.setUTCHours(0, 0, 0, 0);
      const timestamp = fecha.getTime();

      // console.log(timestamp, dateSelect);

      const date = itemRedux.cuposPorDia.find((item) => {
        // Convertir cada fecha de la data al inicio del día (00:00:00) en UTC
        const itemFecha = new Date(parseInt(item.fecha, 10));
        itemFecha.setUTCHours(0, 0, 0, 0);
        const itemTimestamp = itemFecha.getTime();
        if (itemTimestamp == timestamp) {
          setAvaliableDaySelected(item.disponibles);
          return item.disponible;
        }
      });
    }
  }, [dateSelect]);

  const totalPriceItems = () => {
    setInitialPrice(
      (itemRedux.priceChildren == "No existe"
        ? userCounter.children * itemRedux.price
        : userCounter.children * itemRedux.priceChildren) +
        (itemRedux.priceDcto != null
          ? userCounter.adults * itemRedux.priceDcto
          : userCounter.adults * itemRedux.price)
    );
  };

  const { incluyes, noIncluyes, getIcon } = useIncludes(itemRedux);

  if (typeProgramg == "ProgrammingPopulateResponse") {
    return (
      <SecondItemDescriptionProgrammings item={itemRedux} counter={counter} typeProgramg = {typeProgramg}/>
    );
  } else {
    return (
      <div className="itemCardTwo_ReservationCard">
        {isLoading && <LottieAnimation />}
        <h3>{itemRedux.name.substring(0, 30)} </h3>
        <p>{itemRedux.description}</p>
        <div className="descriptions_itemCardTwo_ReservationCard">
          {itemRedux.paqueteDiario == true ? (
            <div className="descriptions_itemCardTwo_ReservationCard_itemsDays">
              <div>
                <h4>Rango </h4>
                <h4>{formatDate(itemRedux.startProgram)}</h4>
              </div>
              <div>
                <h4>Fechas</h4>
                <h4>{formatDate(itemRedux.endProgram)}</h4>
              </div>
            </div>
          ) : (
            <div>
              <h4>Fecha salida</h4>
              <h4>{formatDate(itemRedux.startProgram)}</h4>
            </div>
          )}
          <div>
            <h4>Hora de salida</h4>
            <h4>{formatDateTime(itemRedux.startProgram)}</h4>
          </div>
          {/* <div>
            <h4>Duración</h4>
            <h4>
              {" "}
              {formatDateTimeDuration(
                itemRedux.startProgram,
                itemRedux.endProgram
              )}{" "}
            </h4>
          </div> */}
          <div>
            <h4>Transporte</h4>
            <h4>{itemRedux.typeTransport}</h4>
          </div>
          <div>
            <h4>Acomodación</h4>
            <h4>{itemRedux.acomodacion}</h4>
          </div>
        </div>

        {/* items solo para Turismos */}

        {activeReservation && typeProgramg ? (
          <div className="includes_turims_ReservationCard">
            <h3> {incluyes.length > 0 ? "El paquete incluye :" : null} </h3>

            <div className="includes_turims_ReservationCard_items">
              {incluyes.map((item, index) => {
                return (
                  <div className="item_includes_reservation" key={index}>
                    <p>{item}</p>
                    {getIcon(item)}
                  </div>
                );
              })}
            </div>
            <h3> {noIncluyes.length > 0 ? "El paquete NO incluye :" : null}</h3>
            <div className="includes_turims_ReservationCard_items">
              {noIncluyes.map((item, index) => {
                return (
                  <div className="item_includes_reservation" key={index}>
                    <p>{item}</p>

                    {getIcon(item)}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}

        {itemRedux.paqueteDiario && activeReservation && (
          <>
            <div className={ itemRedux.paqueteDiario == true ? "pay_itemCard_ReservationCard" : "no_True_pay_itemCard_ReservationCard"}>
              <div className="calendar-items-day-reservation">
                <h3>Fecha de salida : </h3>

                <button
                  onClick={() => {
                    // console.log(
                    //   "item",
                    //   itemRedux,
                    //   typeProgramg,
                    //   currentDate,
                    //   "----diaseleccionado-----",
                    //   dateSelect
                    // );
                    setShowCalendar(true);
                  }}
                >
                  <div className="calendar-text-items">
                    <h5>
                      {dateSelect != ""
                        ? dateSelect.toLocaleDateString("es-CO", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                        : new Date().toLocaleDateString("es-CO", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })}
                    </h5>
                    <ion-icon name="calendar-outline"></ion-icon>
                  </div>
                </button>
              </div>

              <h3 className="dayAvaliable-user">
                Cupos disponibles:{" "}
                {avaliableDaySelected != 0 ? avaliableDaySelected : " 0  "}
              </h3>
            </div>
          </>
        )}

        {showCalendar && (
          <div className="containerCalendar">
            {
              <SelectCalendarDates
                availableDates={itemRedux.cuposPorDia}
                showCalendar={showCalendarState}
                selectDate={userDateSelect}
                avaliableDaySelected={setAvaliableDaySelected}
              />
            }
          </div>
        )}

        {activeReservation && typeProgramg ? (
          <div className={ itemRedux.paqueteDiario == true ? "items_counter_" : "NoDiario_items_counter_"}>
            <PassengerQuantity
              available={itemRedux.available}
              showCounter={counter}
            />

            <div className="price_in_counter-persons">
              <h3 style={{ color: "#EC920B", marginRight: "1rem" }}>Precio </h3>
              <h3>
                {formatPrice(initialPrice == 0 ? itemRedux.priceDcto != null ? itemRedux.priceDcto : itemRedux.price : initialPrice)}
            
              </h3>
            </div>
          </div>
        ) : null}

        {activeReservation && typeProgramg ? (
          <div className="pay_itemCard_ReservationCard_two">
            <div className="price_text-calendar">
              <button
                className="button_reservation-turism"
                onClick={() => {
                  // console.log("item", itemRedux, typeProgramg);
                  // console.log(
                  //   "el usuario esta logeado",
                  //   userIsloged,
                  //   dateSelect
                  // );

                  if (userIsloged) {
                    // console.log(
                    //   "usuarios en el boton de reservar",
                    //   userCounter,
                    //   itemRedux,
                    //   dateSelect
                    // );

                    if (userCounter.totalPassengers === 0) {
                      Swal.fire({
                        icon: "info",
                        title: "Seleccionar pasajeros",
                        text: "Ingrese la cantidad de pasajeros para continuar con la reserva",
                        showConfirmButton: false,
                        timer: 2000,
                      });
                      return;
                    }
                    if (userCounter.totalPassengers > 10) {
                      Swal.fire({
                        icon: "info",
                        title: "cantidad maxima de compra es 6 personas ",
                        text: "Ingrese la cantidad de pasajeros correcta para continuar con la reserva",
                        showConfirmButton: false,
                        timer: 2000,
                      });
                      return;
                    }

                    if (itemRedux.paqueteDiario == true && dateSelect == "") {
                      Swal.fire({
                        title: "Por favor Seleccione una fecha ",
                        icon: "warning",
                        showCancelButton: false,
                        showConfirmButton: false,
                        timer: 2000,
                      });
                      return;
                    }

                    dispatch(
                      getDateUser({
                        selectDateUser:
                          formateNewDateOfCalendar(dateSelect) != ""
                            ? formateNewDateOfCalendar(dateSelect)
                            : "No existe",
                      })
                    );
                    setIsLoading(true);
                    navigate(`/reservar-programa`);
                  } else {
                    Swal.fire({
                      title: "Debes iniciar sesión para reservar",
                      icon: "warning",
                      showCancelButton: false,
                      showConfirmButton: false,
                      timer: 2000,
                    });
                    navigate("/Login");
                  }
                }}
              >
                Reservar <ion-icon name="arrow-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
};
