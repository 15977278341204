import "@splidejs/react-splide/css";
import { AppRouter } from "./routers/AppRouter";
import { useEffect, useState } from "react";
import { getAllTurims } from "./services/turims";
import { getAllDestinations } from "../src/services/destinations";
import { LottieAnimation } from "./components/LottieAnimation/LottieAnimation";
import { getFilterDataDates, getFilterDatesProgramings } from "./hooks/dataFilterDates";

const App = () => {
  const [turimsData, setTurimsData] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await getTurims();
      await getDestinations();
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };


  const getTurims = async () => {
    try {
      const filteredResponse = await getFilterDataDates();
      setTurimsData(filteredResponse); 
    } catch (error) {
      console.error("Error fetching turims:", error);
      throw error;
    }
  };
  
  

  const getDestinations = async () => {
    try {
      const response = await getFilterDatesProgramings();
      setDestinations(response);
    }
    catch (error) {
      console.error("Error fetching destinations:", error);
      throw error;
    }
  };

  return (
    <div>
      {isLoading ? (
        <LottieAnimation />
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <AppRouter dataTurims={turimsData} dataDestinations={destinations} />
      )}
    </div>
  );
};

export default App;

