import "./styles.css";
import { useSelector , useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { Carrusel } from "../../components/MainInformation/RecommendedFilters/Carrusel";
import { ItemPrincipalDescriptionCard } from "../../components/ItemPrincipalDescriptionCard/ItemPrincipalDescriptionCard";
import { SecondItemDescriptionCard } from "../../components/SecondItemDescriptionCard/SecondItemDescriptionCard";
import Gallery from "../../components/Gallery/Gallery";

import {
  setAdultPassengers,
  setChildPassengers,
} from "../../redux/passengersSlice";

const linkURL = process.env.REACT_APP_IMAGE_URL
export const DescriptionCard = ({ dataDestination, dataTurism }) => {
  const itemRedux = useSelector((state) => state.itemProduct);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setAdultPassengers(0));
    dispatch(setChildPassengers(0));
    return 
  }, []); 

  return (
    <div className="container_ReservationCard">
      <h1 style={{paddingTop : "1rem"}}>
        {itemRedux.typename == "ProgrammingPopulateResponse"
          ? "Tu mejor destino"
          : "Paquetes turísticos"}
      </h1>
      <div className="item_one_ReservationCard">
        <img
          src={(itemRedux.imagen == null || itemRedux.imagen == undefined || itemRedux.imagen == "") ? `${linkURL}/logo_negro.png` : `${linkURL}${itemRedux.imagen}` }
          alt="imagen"
        />
        <div className="item_one_description_ReservationCard">
          <h2>{itemRedux.name.substring( 0 , 30)}</h2>
          <ItemPrincipalDescriptionCard
            days={itemRedux.day}
            nights={itemRedux.night}
            acomodacion={itemRedux.acomodacion}
            ubication={itemRedux.destination}
            typeProgramg={itemRedux.typename}
            origen={itemRedux.origen}
          />
        </div>
      </div>

      <div className="item_two_ReservationCard">
        <SecondItemDescriptionCard typeProgramg={itemRedux.typename} counter={true} />
      </div>

      {itemRedux.typename == "TourismPopulateResponseView" ? (
        <div className="gallery_ReservationCard">
          <Gallery data={itemRedux.galery} />
        </div>
      ) : null}
      <div
        style={{
          width: "100%",
          background: "#2F2F2F",
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <div className="carrosel_reservationCard">
          {itemRedux.typename === "ProgrammingPopulateResponse" ? (
            <Carrusel title={"Destinos recomendados"} data={dataDestination} />
          ) : (
            <Carrusel title={"Turismos recomendados"} data={dataTurism} />
          )}
        </div>
      </div>
    </div>
  );
};
