import { useNavigate } from "react-router-dom";
import {
  formatDate,
  formatDateTime,
  formatDateTimeDuration,
} from "../../utils/formatDate";
import { formatPrice } from "../../utils/formatPrice";
import { LottieAnimation } from "../LottieAnimation/LottieAnimation";
import "./styles.css";
import { useEffect, useState } from "react";
import { PassengerQuantity } from "../PassengerQuantity/PassengerQuantity";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

export const SecondItemDescriptionProgrammings = ({
  item,
  counter,
  activeReservation = true,
  typeProgramg,
}) => {
  const navigate = useNavigate();
  const itemRedux = useSelector((state) => state.itemProduct);
  const userCounter = useSelector((state) => state.passengers);

  const userIsloged = useSelector((state) => state.user.logged);
  const [isLoading, setIsLoading] = useState(true);
  const [styleProgramg, setStyleProgramg] = useState(true);
  const [initialPrice, setInitialPrice] = useState(0);
  const [initialPriceF, setInitialPriceF] = useState(
    itemRedux.priceDcto ? itemRedux.priceDcto : itemRedux.price
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    if (isLoading) {
      document.body.classList.add("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isLoading]);

  useEffect(() => {
    totalPriceItems();
  }, [userCounter]);

  const totalPriceItems = () => {
    setInitialPrice(
      (itemRedux.priceChildren === "No existe"
      ? userCounter.children * (itemRedux.priceDcto != null ? itemRedux.priceDcto : itemRedux.price)
      : userCounter.children * (itemRedux.priceChildrenDcto != null ? itemRedux.priceChildrenDcto : itemRedux.priceChildren)) +
      (itemRedux.priceDcto != null
      ? userCounter.adults * itemRedux.priceDcto
      : userCounter.adults * itemRedux.price)
    );
  };

  return (
    <div className="itemCardTwo_ReservationCard">

      {isLoading && <LottieAnimation />}
      
      <h3>Características </h3>
      <p>{item.description}</p>
      <div className="descriptions_itemCardTwo_ReservationCard">
        <div>
          <h4>Fecha</h4>
          <h4>{formatDate(item.startProgram)}</h4>
        </div>
        <div>
          <h4>Hora de Salida</h4>
          <h4>{formatDateTime(item.startProgram)}</h4>
        </div>
        <div>
          <h4>Duración</h4>
          <h4>{formatDateTimeDuration(item.startProgram, item.endProgram)}</h4>
        </div>
        <div>
          <h4>Disponibles</h4>
          <h4>{item.available}</h4>
        </div>
      </div>

      {activeReservation && typeProgramg && counter  ? (
          <div className={ styleProgramg ? "ProgrammingsStyleCounter" : null}>
            <PassengerQuantity
              available={itemRedux.available}
              showCounter={counter}
            />

            <div className="price_in_counter-persons">
              <h3 style={{ color: "#EC920B", marginRight: "1rem" }}>Precio </h3>
              <h3>
              {formatPrice(initialPrice == 0 ?  itemRedux.priceDcto ? itemRedux.priceDcto : itemRedux.price : initialPrice)}
              </h3>
            </div>
          </div>
        ) : null}

      {activeReservation && typeProgramg  && counter ? (
        <div className="pay_itemCard_ReservationCard_two">
          <div className="price_text-calendar">
            <button
              className="button_reservation-turism"
              onClick={() => {
                // console.log("item", itemRedux, typeProgramg);
                // console.log("el usuario esta logeado", userIsloged);

                if (userIsloged) {
                  // console.log(
                  //   "usuarios en el boton de reservar",
                  //   userCounter,
                  //   itemRedux
                  // );

                  if (userCounter.totalPassengers === 0) {
                    Swal.fire({
                      icon: "info",
                      title: "Seleccionar pasajeros",
                      text: "Ingrese la cantidad de pasajeros para continuar con la reserva",
                      showConfirmButton: false,
                      timer: 2000,
                    });
                    return;
                  }
                  if (userCounter.totalPassengers > 10) {
                    Swal.fire({
                      icon: "info",
                      title: "cantidad maxima de compra es 6 personas ",
                      text: "Ingrese la cantidad de pasajeros correcta para continuar con la reserva",
                      showConfirmButton: false,
                      timer: 2000,
                    });
                    return;
                  }

                  setIsLoading(true);
                  navigate(`/reservar-programa`);
                } else {
                  Swal.fire({
                    title: "Debes iniciar sesión para reservar",
                    icon: "warning",
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  navigate("/Login");
                }
              }}
            >
              Reservar <ion-icon name="arrow-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      ) 
      
      
      
      : null}






    </div>
  );
};
